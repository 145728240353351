import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Hero from "../components/Hero/Hero";
import PicsGrid from "../components/PicsGrid/PicsGrid";
import Description from "../components/Description/Description";

import socialMediaVideoHevc from "../videos/social_media_project/social-media-h265.mp4";
import socialMediaVideoWebm from "../videos/social_media_project/social-media-vp9.webm";
import socialMediaVideoMp4 from "../videos/social_media_project/social-media-h264.mp4";
import socialMediaPoster from "../videos/social_media_project/social-media-poster.jpg";

export const socialMediaImage = graphql`
  fragment socialMediaImage on File {
    childImageSharp {
      fluid(maxHeight: 1200, quality: 100) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`;

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "social_media/the-attico.jpg" }) {
      ...socialMediaImage
    }
    image2: file(relativePath: { eq: "social_media/the-attico-2.jpg" }) {
      ...socialMediaImage
    }
    image3: file(relativePath: { eq: "social_media/the-attico-3.jpg" }) {
      ...socialMediaImage
    }
    image4: file(relativePath: { eq: "social_media/3L7A9269.jpg" }) {
      ...socialMediaImage
    }
    image5: file(relativePath: { eq: "social_media/saucony-studio.jpg" }) {
      ...socialMediaImage
    }
    image6: file(relativePath: { eq: "social_media/3L7A1127.jpg" }) {
      ...socialMediaImage
    }
    image7: file(relativePath: { eq: "social_media/hb.jpg" }) {
      ...socialMediaImage
    }
    image8: file(relativePath: { eq: "social_media/hb-2.jpg" }) {
      ...socialMediaImage
    }
    image9: file(relativePath: { eq: "social_media/hb-3.jpg" }) {
      ...socialMediaImage
    }
  }
`;

const SocialMedia = ({ data }) => (
  <Layout pageUrl="social-media-project">
    <SEO title="Social Media Project" />
    <Hero
      home={false}
      pageTitle="Social Media Project"
      videoSrcHevc={socialMediaVideoHevc}
      videoSrcWebm={socialMediaVideoWebm}
      videoSrcMp4={socialMediaVideoMp4}
      videoPoster={socialMediaPoster}
    />
    <PicsGrid
      pic1={data.image1.childImageSharp.fluid}
      alt1="Social media showcase"
      pic2={data.image2.childImageSharp.fluid}
      alt2="Social media showcase"
      pic3={data.image3.childImageSharp.fluid}
      alt3="Social media showcase"
    />
    <Description
      heading="Una strategia social efficace e contemporanea"
      description="Il mondo dei Social Media ha le sue regole, e noi di Parallel Studio lo sappiamo bene. La nostra proposta è quella di una vera e propria strategia creativa nella sua interezza, messa a punto in collaborazione con il cliente e i suoi art director di riferimento. Le attività che proponiamo comprendono l'individuazione dei trend del settore e del target di riferimento, assieme a una pianificazione della campagna social che consenta di ottimizzare i tempi e i costi di realizzazione. In base alle specifiche necessità, inoltre, mettiamo a disposizione il nostro team formato da esperti di marketing, art director, producer, fotografi, videomaker e stylist, pronti a prendersi cura del progetto creativo."
    />
    <PicsGrid
      pic1={data.image4.childImageSharp.fluid}
      alt1="Social media showcase"
      pic2={data.image5.childImageSharp.fluid}
      alt2="Social media showcase"
      pic3={data.image6.childImageSharp.fluid}
      alt3="Social media showcase"
    />
    <PicsGrid
      pic1={data.image7.childImageSharp.fluid}
      alt1="Social media showcase"
      pic2={data.image8.childImageSharp.fluid}
      alt2="Social media showcase"
      pic3={data.image9.childImageSharp.fluid}
      alt3="Social media showcase"
    />
  </Layout>
);

export default SocialMedia;
